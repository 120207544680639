<template>
  <v-icon
      class="mr-3"
      :color="checkIconStyle.color"
      v-text="checkIconStyle.icon"
  ></v-icon>
</template>

<script>
export default {
  name: 'CheckIcon',
  props: {
    checked: {},
  },
  computed: {
    checkIconStyle() {
      return this.checked ? {
        icon: 'mdi-check-circle',
        color: 'green',
      } : {
        icon: 'mdi-checkbox-blank-circle-outline',
        color: '',
      };
    },
  },
};
</script>
