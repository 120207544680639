<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip label class="mr-1 mb-1" v-on="on" v-bind="attrs">
        <v-icon left v-text="icon"></v-icon>
        <span><slot></slot></span>
      </v-chip>
    </template>

    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ProfileAttributeChip',
  props: {
    icon: String,
    tooltip: String,
  },
};
</script>
