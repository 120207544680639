<template>
  <div class="bottom-action-bar">
    <v-system-bar
      height="36"
      v-if="$slots.statusbar"
    >
      <slot name="statusbar"></slot>
    </v-system-bar>
    <v-toolbar
      height="72"
    >
      <v-container>
        <v-row no-gutters>
          <slot></slot>
        </v-row>
      </v-container>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'BottomActionBar',
};
</script>

<style scoped>
.bottom-action-bar {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
}

.bottom-action-bar >>> .v-toolbar__content {
  padding: 0;
}
</style>
