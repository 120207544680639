<template>
  <v-alert
    elevation="0"
    class="reservation-card-placeholder d-flex align-center"
    color="#f6f6f6"
    height="64"
  >
    <v-list-item
      class="px-0"
      two-line
    >
      <v-list-item-content>
        <v-list-item-title class="text--disabled"><slot></slot></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-alert>
</template>

<script>
export default {
  name: 'ReservationCardPlaceholder',
};
</script>

<style scoped>
.reservation-card-placeholder {
  border-radius: 12px;
}

.reservation-card-placeholder >>> .v-alert__wrapper {
  width: 100%;
}
</style>
