<template>
  <v-btn
      rounded x-large
      class="text-button"
      v-text="$t('manualCheckIn')"
      :loading="loading"
      :disabled="loading"
      @click="manualCheckinButton"
  ></v-btn>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'ManualCheckinButton',
  props: {
    reservation: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions('reservations', [
      'manualCheckin',
    ]),
    async manualCheckinButton() {
      this.loading = true;
      await this.manualCheckin(this.reservation);
      this.loading = false;
    },
  },
};
</script>
