/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { DEFAULT_LOCALE, loadLocale } from '@/plugins/i18n';
import * as types from '../mutation-types';

const state = {
  language: DEFAULT_LOCALE,
};

const getters = {
  language: (state) => state.language,
};

const actions = {
  init({ state, dispatch }) {
    dispatch('setLanguage', state.language);
  },
  setLanguage({ commit }, language) {
    loadLocale(language);
    commit(types.SETTINGS_SET_LANUGAGE, language);
  },
};

const mutations = {
  [types.SETTINGS_SET_LANUGAGE](state, language) {
    state.language = language;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
