import moment from 'moment';
import i18n from '@/plugins/i18n';
import { daysFromNow } from '@/utils/reservations';

export default class Reservation {
  static STATUS_NOT_PREPARED = 'not_prepared';

  static STATUS_READY = 'ready_for_pickup';

  constructor(obj) {
    // eslint-disable-next-line no-unused-expressions
    obj && Object.assign(this, obj);
  }

  get startDate() { return moment(this.start_date); }

  get endDate() { return moment(this.end_date); }

  get isToday() { return moment().date() === this.startDate.date(); }

  getDateRangeString() {
    return `${this.startDate.format('DD.MM.YYYY')} - ${this.endDate.format('DD.MM.YYYY')}`;
  }

  getStartDateString() {
    const days = daysFromNow(this);

    if (days >= 0 && days < 3) {
      return i18n.tc('datetime.inDays', days);
    }
    return this.startDate.format('DD.MM.YYYY');
  }

  isWaitingForPickup() {
    return this.checkin_type && !this.pickup_completed_by_employee;
  }

  isCompleted() {
    return !!this.pickup_completed_by_employee;
  }
}
