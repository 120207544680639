// eslint-disable-next-line import/prefer-default-export
export const NAVIGATION_TOGGLE_APP_DRAWER = 'NAVIGATION_TOGGLE_APP_DRAWER';
export const NAVIGATION_SET_APP_DRAWER = 'NAVIGATION_SET_APP_DRAWER';

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_SET_BOOTH = 'AUTH_SET_BOOTH';

export const SETTINGS_SET_LANUGAGE = 'SETTINGS_SET_LANUGAGE';

export const RESERVATIONS_LOAD_RESERVATIONS = 'RESERVATIONS_LOAD_RESERVATIONS';
export const RESERVATIONS_UPDATE_RESERVATION = 'RESERVATIONS_UPDATE_RESERVATION';
export const RESERVATIONS_SET_LOADING = 'RESERVATIONS_SET_LOADING';

export const RESERVATION_PROFILES_UPDATE_PROFILE = 'RESERVATION_PROFILES_UPDATE_PROFILE';
