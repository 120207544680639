<template>
  <v-col class="pa-1">
    <v-btn
      block large
      outlined
      class="text-button"
      @click="$emit('input', number)"
      v-text="number"
      height="56"
    ></v-btn>
  </v-col>
</template>
<script>
export default {
  name: 'NumericInputDialogButton',
  props: {
    number: String,
  },
};
</script>
