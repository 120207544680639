<template>
  <v-btn
      x-large
      rounded
      color="white"
      class="mt-5 text-h6"
      light
      @click="confirmButton"
      :loading="loading"
      :disabled="loading"
  >
    <span
      class="text--primary"
      v-text="$t('confirmPickupNotification')"
    ></span>
  </v-btn>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'ConfirmCheckinButton',
  props: {
    reservation: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions('reservations', [
      'confirmCheckin',
    ]),
    async confirmButton() {
      this.loading = true;
      await this.confirmCheckin(this.reservation);
      this.loading = false;
      this.$emit('success');
    },
  },
};
</script>
