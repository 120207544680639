import Vue from 'vue';
import VueI18n from 'vue-i18n';

import messages from '@/lang/de.json';

Vue.use(VueI18n);

export const DEFAULT_LOCALE = 'de';

const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  messages: { [DEFAULT_LOCALE]: messages },
});

// We preload the default locale, if any additional locales are loaded,
// we add them to the array for instant switching
const loadedLocales = [DEFAULT_LOCALE];

export function setLocale(locale) {
  i18n.locale = locale;

  // Set the document language
  document.querySelector('html').setAttribute('lang', locale);

  return locale;
}

export function loadLocale(locale) {
  // If this locale is already set
  if (i18n.locale === locale) return Promise.resolve(setLocale(locale));

  // If the locale was already loaded
  if (loadedLocales.includes(locale)) return Promise.resolve(setLocale(locale));

  // If the language hasn't been loaded yet, load it using webpack chunks
  return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${locale}.json`).then(
    (loadedMessages) => {
      i18n.setLocaleMessage(locale, loadedMessages.default);
      loadedLocales.push(locale);
      return setLocale(locale);
    },
  );
}

export default i18n;
