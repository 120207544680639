import moment from 'moment';
import i18n from '@/plugins/i18n';

export function daysFromNow(reservation) {
  const daysFloat = moment.duration(
    reservation.startDate.diff(moment()),
  ).asDays();

  return Math.ceil(daysFloat);
}

export function groupReservationsByDays(reservations) {
  const overdue = [];
  const today = [];
  const tomorrow = [];
  const thisWeek = [];

  reservations.forEach((reservation) => {
    const days = daysFromNow(reservation);

    if (days < 0) overdue.push(reservation);
    else if (days === 0) today.push(reservation);
    else if (days === 1) tomorrow.push(reservation);
    else if (days <= 4) thisWeek.push(reservation);
  });

  const dayRanges = [];

  if (today.length > 0 || tomorrow.length > 0 || thisWeek.length > 0 || overdue.length > 0) {
    dayRanges.push({
      label: i18n.t('datetime.today'),
      reservations: today,
    });
  }

  if (tomorrow.length > 0 || thisWeek.length > 0 || overdue.length > 0) {
    dayRanges.push({
      label: i18n.t('datetime.tomorrow'),
      reservations: tomorrow,
    });
  }

  if (thisWeek.length > 0 || overdue.length > 0) {
    dayRanges.push({
      label: i18n.t('datetime.thisWeek'),
      reservations: thisWeek,
    });
  }

  if (overdue.length > 0) {
    dayRanges.push({
      label: i18n.t('datetime.overdue'),
      reservations: overdue,
    });
  }

  return dayRanges;
}

export function sortByDate(a, b) {
  return a.startDate.unix() - b.startDate.unix();
}
