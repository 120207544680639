<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="{ on }"></slot>
    </template>

    <v-card>
      <v-card-title class="pl-4">
        <span class="text-h6" v-text="label"></span>
      </v-card-title>
      <v-card-text>
        <v-row class="px-1 pb-4">
          <v-text-field
            outlined
            clearable
            readonly
            hide-details
            v-model="input"
          ></v-text-field>
        </v-row>
        <v-row>
          <numeric-input-dialog-button number="1" @input="onInput"></numeric-input-dialog-button>
          <numeric-input-dialog-button number="2" @input="onInput"></numeric-input-dialog-button>
          <numeric-input-dialog-button number="3" @input="onInput"></numeric-input-dialog-button>
        </v-row>
        <v-row>
          <numeric-input-dialog-button number="4" @input="onInput"></numeric-input-dialog-button>
          <numeric-input-dialog-button number="5" @input="onInput"></numeric-input-dialog-button>
          <numeric-input-dialog-button number="6" @input="onInput"></numeric-input-dialog-button>
        </v-row>
        <v-row>
          <numeric-input-dialog-button number="7" @input="onInput"></numeric-input-dialog-button>
          <numeric-input-dialog-button number="8" @input="onInput"></numeric-input-dialog-button>
          <numeric-input-dialog-button number="9" @input="onInput"></numeric-input-dialog-button>
        </v-row>
        <v-row>
          <v-col class="pa-1"></v-col>
          <numeric-input-dialog-button number="0" @input="onInput"></numeric-input-dialog-button>
          <v-col class="pa-1">
            <v-btn
              block large
              elevation="0"
              class="text-button"
              height="56"
              @click="backspace"
            ><v-icon>mdi-backspace</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-row class="px-1 pt-4">
          <v-btn
            block large
            color="primary"
            class="text-button"
            @click="confirmInput"
            :disabled="!valid"
          >OK</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import NumericInputDialogButton from '@/components/NumericInputDialogButton';

export default {
  name: 'NumericInputDialog',
  components: { NumericInputDialogButton },
  props: {
    label: String,
    maxLength: Number,
  },
  data() {
    return {
      dialog: false,
      input: null,
    };
  },
  computed: {
    valid() {
      return this.input && (!this.maxLength || (this.input.length <= this.maxLength));
    },
  },
  methods: {
    onInput(number) {
      if (this.input !== null) this.input += number;
      else this.input = number;
    },
    backspace() {
      if (this.input) {
        if (this.input.length <= 1) this.input = null;
        else this.input = this.input.substring(0, this.input.length - 1);
      }
    },
    confirmInput() {
      if (this.input !== null) {
        this.$emit('input', this.input);
        this.input = null;
        this.dialog = false;
      }
    },
  },
};
</script>
