export default class ReservationProfile {
  static STATUS_ADDITIONAL_DATA_NEEDED = 'additional_data_needed'

  static STATUS_NOT_PREPARED = 'not_prepared'

  static STATUS_READY = 'ready_for_pickup'

  constructor(data) {
    // eslint-disable-next-line no-unused-expressions
    data && Object.assign(this, data);
  }
}
