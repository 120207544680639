<template>
  <v-expansion-panel
      class="mt-4 gear-card"
      :key="$vnode.key"
  >
    <v-expansion-panel-header class="px-4">
      <v-row no-gutters class="align-center">
        <check-icon v-if="edit" :checked="completed"></check-icon>
        <h3
          class="text-h6"
          v-text="`${profileSnapshot.first_name} ${profileSnapshot.last_name}`"
        ></h3>
      </v-row>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <v-row class="px-4">
        <v-col>
          <profile-attributes :profile-snapshot="profileSnapshot"></profile-attributes>
        </v-col>
        <!-- Barcode -->
        <v-col cols="4" md="3" v-if="profile.barcode">
          <barcode
            style="width: 100%"
            bcid="code39"
            includetext
            :scale="4"
            :height="16"
            :text="profile.barcode"
          ></barcode>
        </v-col>
      </v-row>

      <!-- Gear Table-->
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left" v-text="$t('product')"></th>
            <th class="text-left" v-text="$t('subtype')"></th>
            <th class="text-left" v-text="$t('previousModel')"></th>
          </tr>
        </thead>
        <tbody>
          <!-- Gear -->
          <tr
            v-for="(reservationItem) in profile.reservation_items"
            :key="reservationItem.booth_item_id"
          >
            <td v-text="reservationItem.gear_type"></td>
            <td>
              <span v-text="reservationItem.gear_subtype"></span>
              <span
                v-if="reservationItem.price_category"
                v-text="' ' + reservationItem.price_category"
              ></span>
            </td>
            <td v-text="reservationItem.previous_model || '-'"></td>
          </tr>

          <!-- Skipass entry-->
          <tr v-if="profile.ticket">
            <td v-text="profile.ticket.display_name"></td>
            <td v-text="profile.ticket.ticket_description"></td>
            <td>
              <v-progress-circular v-if="skipassLoading" indeterminate></v-progress-circular>
              <ski-pass-table-cell
                v-else
                :card="profile.ticket.card"
                @input="onSkipass"
              ></ski-pass-table-cell>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-row no-gutters class="px-4 mt-3" v-if="edit && !completed">
        <v-spacer></v-spacer>
        <v-btn
          large
          class="text-button"
          color="green"
          rounded
          v-text="$t('done')"
          :dark="valid && !loading"
          :disabled="!valid || loading"
          :loading="loading"
          @click="markReady"
        ></v-btn>
      </v-row>

      <v-snackbar
        v-model="hasError"
        :timeout="5000"
        top
        color="error" dark
      >
        <span class="font-weight-bold">{{ errorMessage }}</span>
      </v-snackbar>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ReservationProfilesApi from '@/api/reservation-profiles';
import CheckIcon from '@/views/reservation-detail/components/CheckIcon';
import SkiPassTableCell from './SkiPassTableCell';
import ProfileAttributes from './ProfileAttributes';

export default {
  name: 'PersonCard',
  components: {
    CheckIcon,
    ProfileAttributes,
    SkiPassTableCell,
    Barcode: () => import('@/components/Barcode' /* webpackChunkName: "barcode" */),
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    edit: Boolean,
  },
  data() {
    return {
      loading: false,
      skipassLoading: false,
      hasError: false,
      errorMessage: null,
    };
  },
  computed: {
    profileSnapshot() {
      return this.profile ? this.profile.profile_snapshot : null;
    },
    valid() {
      return !this.profile.ticket || (!!this.profile.ticket && !!this.profile.ticket.card);
    },
    completed() {
      return this.profile.preparation_status === 'ready_for_pickup';
    },
  },
  methods: {
    async onSkipass(number) {
      this.skipassLoading = true;
      try {
        const response = await ReservationProfilesApi.addCard(this.profile, number);
        this.$emit('change', response);
      } catch (e) {
        this.hasError = true;
        this.errorMessage = e.response.data.message;
      } finally {
        this.skipassLoading = false;
      }
    },
    async markReady() {
      this.loading = true;
      try {
        const response = await ReservationProfilesApi.markReady(this.profile);
        this.$emit('change', response);
      } catch (e) {
        this.hasError = true;
        this.errorMessage = e.response.data.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.gear-card >>> .v-expansion-panel-content__wrap {
  padding-left: 0;
  padding-right: 0;
}
</style>
