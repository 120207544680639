<template>
  <v-alert
    v-ripple
    class="reservation-card d-flex align-center"
    :height="height"
    :dark="isFullColor"
    :outlined="!isFullColor"
    :color="color"
    @click="onClick"
  >
    <v-list-item
      class="px-0"
      two-line
    >
      <v-list-item-content>
        <v-list-item-title
          :class="{ 'text-h6': waitingForPickup }"
          class="reservation-card__title"
          v-text="reservation.name"
        ></v-list-item-title>
        <v-list-item-subtitle
          v-text="reservation.getStartDateString()"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <div class="d-flex justify-center align-center" v-if="waitingForPickup">
        <v-chip v-if="timer" large :color="timerColor" class="timer-chip">
          <span class="text-button timer-chip__text" v-text="timer"></span>
        </v-chip>
    </div>
    <v-icon v-if="showIndicator" small :color="showIndicator">mdi-circle</v-icon>
  </v-alert>
</template>

<script>
import moment from 'moment';
import Reservation from '@/models/reservation';

export default {
  name: 'ReservationCard',
  props: {
    reservation: {
      type: Reservation,
      required: true,
    },
    waitingForPickup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
      timerColor: 'green',
      timerInterval: null,
    };
  },
  created() {
    if (this.reservation.checkin_time) {
      this.updateTimer();
      setInterval(this.updateTimer, 100);
    }
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  computed: {
    height() { return this.waitingForPickup ? 92 : 64; },
    color() {
      if (!(this.isFullColor || this.hasBorder)) return null;

      if (this.reservation.isWaitingForPickup()) return 'primary';

      switch (this.reservation.preparation_status) {
      case Reservation.STATUS_NOT_PREPARED:
        return 'warning';
      case Reservation.STATUS_PREPARED:
        return 'green';
      default:
        return 'grey';
      }
    },
    isFullColor() {
      return this.reservation.isWaitingForPickup();
    },
    showIndicator() {
      if (this.reservation.isWaitingForPickup()) return false;

      switch (this.reservation.preparation_status) {
      case Reservation.STATUS_NOT_PREPARED:
        return this.reservation.isToday ? 'orange' : null;
      case Reservation.STATUS_READY:
        return this.reservation.isToday ? 'green' : null;
      default:
        return false;
      }
    },
    border() {
      return this.hasBorder ? 'right' : null;
    },
  },
  methods: {
    onClick() {
      this.$router.push({
        name: 'reservation-detail',
        params: { id: this.reservation.id },
      });
    },
    updateTimer() {
      const checkinTime = moment(this.reservation.checkin_time);
      // eslint-disable-next-line max-len
      const durationInSeconds = 60 * 3 - Math.round(moment.duration(moment().diff(checkinTime)).asSeconds());

      if (durationInSeconds > 0) {
        const minutes = String(Math.floor(durationInSeconds / 60)).padStart(2, '0');
        const seconds = String(durationInSeconds % 60).padStart(2, '0');

        this.timer = `${minutes}:${seconds}`;
      } else {
        this.timer = null;
      }

      if (durationInSeconds > 120) {
        this.timerColor = 'success';
      } else if (durationInSeconds > 60) {
        this.timerColor = 'warning';
      } else {
        this.timerColor = 'red';
      }
    },
  },
};
</script>

<style scoped>
.reservation-card >>> .v-alert__wrapper {
  width: 100%;
}

.reservation-card >>> .v-alert__content {
  display: flex;
  flex: 1;
}

.reservation-card {
  border-color: rgba(0,0,0,0.6)!important;
  border-radius: 12px!important;
  border-width: 2px!important;
}

.reservation-card__title {
  font-weight: 700;
}

.timer-chip {
  min-width: 140px;
  display: flex;
  justify-content: center;
}

.timer-chip__text {
  font-size: 2rem !important;
  padding: .5rem;
}
</style>
