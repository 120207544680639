<template>
  <v-overlay
    class="alert-overlay"
    :color="isNewReservation ? 'green' : 'primary'"
    opacity="0.9"
    :value="show"
  >
    <template v-if="show">
      <div class="text-center" v-if="isNewReservation" @click="clearAlert">
        <h2 v-text="$t('newReservationNotification')"></h2>
        <h1 v-text="reservation.name"></h1>

        <v-btn
          x-large
          rounded
          color="white"
          class="mt-5 text-h6"
          light
          :to="`/reservation/${reservation.id}`"
        >
    <span
      class="text--primary"
      v-text="$t('goToReservationButton')"
    ></span>
        </v-btn>
      </div>
      <div class="text-center" v-else>
        <h1 v-text="reservation.name"></h1>
        <h2 v-text="$t('pickupNotification')"></h2>

        <confirm-checkin-button
          :reservation="reservation"
          @success="clearAlert"
        ></confirm-checkin-button>
      </div>
    </template>
  </v-overlay>
</template>

<script>
/* global Android */
import eventBus from '@/event-bus';
import { playNotificationSound } from '@/utils/audio';
import ConfirmCheckinButton from '@/components/ConfirmCheckinButton';

export default {
  name: 'PickupAlertOverlay',
  components: { ConfirmCheckinButton },
  data() {
    return {
      show: false,
      reservation: null,
      isNewReservation: false,
    };
  },
  created() {
    eventBus.$on('showPickupAlert', (reservation) => {
      this.showAlert(reservation, false);
    });
    eventBus.$on('showNewReservationAlert', (reservation) => {
      this.showAlert(reservation, true);
    });
  },
  methods: {
    showAlert(reservation, isNewReservation) {
      this.show = true;
      this.isNewReservation = isNewReservation;
      this.reservation = reservation;

      playNotificationSound(1);
      if (typeof Android !== 'undefined') {
        Android.vibrate(1000);
      }

      if (isNewReservation) setTimeout(this.clearAlert, 30000);
    },
    clearAlert() {
      this.show = false;
      this.reservation = null;
    },
  },
};
</script>

<style scoped>
.alert-overlay {
  z-index: 10!important;
}

.alert-overlay >>> .v-overlay__scrim{
  background-image: url("../assets/bg-primary.svg");
}

.alert-overlay >>> .v-overlay__scrim.green{
  filter: hue-rotate(-120deg);
}
</style>
