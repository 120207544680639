<template>
  <v-card
      class="mt-4"
  >
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="2" lg="1">
          <img style="width: 100%" src="@/assets/awesome.svg"/>
        </v-col>
        <v-col class="d-flex flex-column justify-center">
          <h2 class="text--black">{{ $t('reservationDetail.allDone') }}</h2>
          <h3 class="mt-2">{{ $t('reservationDetail.allDoneSubtitle') }}</h3>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AllDoneCard',
};
</script>
