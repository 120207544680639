<template>
  <v-app-bar
      app
      flat dark
      hide-on-scroll
      color="transparent"
      :extended="!$route.meta.compactHeader"
      :class="{ 'center-logo': $route.meta.hideControls || true }"
  >
    <img class="v-app-bar__logo" :src="require('../assets/hyll-logo-dark.svg')" alt="HYLL">

    <env-label></env-label>

    <template v-if="!$route.meta.hideControls">
      <v-spacer></v-spacer>
      <v-btn
          icon
          @click="$store.dispatch('reservations/loadReservations', true)"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-app-bar-nav-icon @click="toggleAppDrawer"></v-app-bar-nav-icon>
    </template>

    <template v-slot:extension v-if="!$route.meta.compactHeader">
      <device-status-bar></device-status-bar>
    </template>

  </v-app-bar>
</template>
<script>
import { mapActions } from 'vuex';
import DeviceStatusBar from '@/components/DeviceStatusBar';
import EnvLabel from '@/components/EnvLabel';

export default {
  name: 'AppBar',
  components: { EnvLabel, DeviceStatusBar },
  methods: {
    ...mapActions('navigation', [
      'toggleAppDrawer',
    ]),
  },
};
</script>

<style>
.v-app-bar.v-app-bar--fixed {
  z-index: 0!important;
}
</style>
