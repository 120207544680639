<template>
  <!-- Compact Style -->
  <v-btn-toggle
    v-if="compact"
    mandatory
    rounded
    v-model="selectedLanguage"
  >
    <v-btn
      v-for="language in availableLanguages"
      :key="language.code"
      :value="language.code"
    >
      {{ language.name }}
    </v-btn>
  </v-btn-toggle>

  <!-- List Item Style -->
  <v-col class="px-4" v-else>
    <span class="text overline">{{ $t('language') }}</span>
    <v-select
      solo
      :items="availableLanguages"
      v-model="selectedLanguage"
      item-text="name"
      item-value="code"
      hide-details
    ></v-select>
  </v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

const availableLanguages = [
  {
    name: 'Deutsch',
    code: 'de',
  },
  {
    name: 'English',
    code: 'en',
  },
];

export default {
  name: 'LanguageSelect',
  props: {
    compact: Boolean,
  },
  data() {
    return {
      availableLanguages,
    };
  },
  computed: {
    ...mapGetters('localSettings', [
      'language',
    ]),
    selectedLanguage: {
      get() {
        return this.language;
      },
      set(language) {
        this.setLanguage(language);
      },
    },
  },
  methods: {
    ...mapActions('localSettings', [
      'setLanguage',
    ]),
  },
};
</script>

<style scoped>

</style>
