<template>
  <v-container class="pt-4 fill-height align-content-start"  v-if="reservation">
    <v-row class="align-center pl-3">
      <h3 class="text-h6">Reservation</h3>
      <v-spacer></v-spacer>
      <v-btn icon class="mr-2" large to="/">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3 v-text="reservation.name"></h3>
            <h4 v-text="reservation.getDateRangeString()"></h4>
          </v-col>

          <v-col cols="4" md="3" v-if="reservation.barcode">
            <barcode
              style="width: 100%"
              bcid="code39"
              includetext
              :scale="4"
              :height="20"
              :text="reservation.barcode"
            ></barcode>
          </v-col>
        </v-row>

        <v-divider class="mt-2"></v-divider>

        <reservation-profiles
          :reservation="reservation"
          :edit="isNew"
          @valid="allProfilesCompleted = $event"
        ></reservation-profiles>

        <all-done-card
          v-if="allProfilesCompleted && isNew"
        ></all-done-card>

        <div style="height: 100px"></div>
      </v-col>
    </v-row>
    <v-row>
      <bottom-action-bar>
        <template slot="statusbar" v-if="reservation.price">
          <v-row no-gutters class="px-2 body-2 font-weight-medium">
            <span
              v-if="reservation.price.total_gear"
              class="mr-3"
              v-text="$t('totalGear', { ...reservation.price })"
            ></span>
            <span
              v-if="reservation.price.total_tickets"
              class="mr-3"
              v-text="$t('totalTickets', { ...reservation.price })"
            ></span>
            <v-spacer></v-spacer>
            <span
              v-if="reservation.price.total_price"
              class="font-weight-bold"
              v-text="$t('totalPrice', { ...reservation.price })"
            ></span>
          </v-row>
        </template>

        <manual-checkin-button
          v-if="!isNew && !isWaitingForPickup && !isCompleted"
          :reservation="reservation"
        ></manual-checkin-button>

        <v-spacer></v-spacer>

        <mark-ready-button
          v-if="isNew"
          :reservation="reservation"
          :disabled="!allProfilesCompleted"
        ></mark-ready-button>

        <complete-pickup-button
          v-if="!isNew && isWaitingForPickup"
          :reservation="reservation"
        ></complete-pickup-button>
      </bottom-action-bar>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Reservation from '@/models/reservation';
import BottomActionBar from '@/components/BottomActionBar';
import ManualCheckinButton from '@/views/reservation-detail/components/ManualCheckinButton';
import MarkReadyButton from '@/views/reservation-detail/components/MarkReadyButton';
import CompletePickupButton from '@/views/reservation-detail/components/CompletePickupButton';
import ReservationProfiles from '@/views/reservation-detail/components/ReservationProfiles';
import AllDoneCard from '@/views/reservation-detail/components/AllDoneCard';

export default {
  name: 'ReservationDetail',
  components: {
    AllDoneCard,
    ReservationProfiles,
    CompletePickupButton,
    MarkReadyButton,
    ManualCheckinButton,
    BottomActionBar,
    Barcode: () => import('@/components/Barcode' /* webpackChunkName: "barcode" */),
  },
  props: {
    id: {},
  },
  data() {
    return {
      allProfilesCompleted: false,
    };
  },
  computed: {
    ...mapGetters('reservations', [
      'reservationById',
    ]),
    reservation() {
      return this.reservationById(this.id);
    },
    isNew() {
      return this.reservation
        && this.reservation.preparation_status === Reservation.STATUS_NOT_PREPARED;
    },
    isWaitingForPickup() {
      return this.reservation && this.reservation.isWaitingForPickup();
    },
    isCompleted() {
      return this.reservation && this.reservation.isCompleted();
    },
  },
  created() {
    this.fetchReservation();
  },
  methods: {
    ...mapActions('reservations', [
      'loadReservations',
      'completePickup',
    ]),
    async fetchReservation() {
      await this.loadReservations(true);
    },
  },
};
</script>
