<template>
  <v-navigation-drawer
    app
    v-model="drawer"
  >
    <v-col class="px-4">
      <v-row class="justify-center my-5">
        <img class="v-app-bar__logo" :src="require('../assets/hyll-logo.svg')" alt="HYLL">
      </v-row>

      <v-row class="justify-center" v-if="booth">
        <span class="text-center" v-text="booth.title"></span>
      </v-row>
    </v-col>

    <v-divider></v-divider>

    <language-select></language-select>

    <v-list-item nav
                 @click="logout"
    >
      <v-list-item-icon>
        <v-icon>mdi-logout</v-icon>
      </v-list-item-icon>
      <v-list-item-title>{{ $t('navigation.logout')}}</v-list-item-title>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as types from '@/store/mutation-types';

import LanguageSelect from './LanguageSelect';

export default {
  name: 'AppNavigationDrawer',
  components: { LanguageSelect },
  computed: {
    ...mapGetters('auth', [
      'booth',
    ]),
    drawer: {
      get() {
        return this.$store.getters['navigation/appDrawerOpen'];
      },
      set(value) {
        this.$store.commit(`navigation/${types.NAVIGATION_SET_APP_DRAWER}`, value);
      },
    },
  },
  methods: {
    ...mapActions('auth', [
      'logout',
    ]),
  },
};
</script>

<style>
.v-navigation-drawer {
  z-index: 10;
}
</style>
