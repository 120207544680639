/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import reservationsApi from '@/api/reservations';
import Reservation from '@/models/reservation';
import { sortByDate } from '@/utils/reservations';
import * as types from '../mutation-types';

const state = {
  reservations: [],
  loading: false,
};

const getters = {
  reservations: (state) => state.reservations,
  loading: (state) => state.loading,
  // eslint-disable-next-line max-len
  reservationById: (state) => (id) => state.reservations.find((r) => String(r.id) === String(id)),
  openReservations(state) {
    return state.reservations
      .filter((r) => r.preparation_status === Reservation.STATUS_NOT_PREPARED)
      .sort(sortByDate);
  },
  readyReservations(state) {
    return state.reservations
      .filter((r) => r.preparation_status === Reservation.STATUS_READY && !r.isCompleted())
      .sort(sortByDate);
  },
  waitingReservations(state) {
    return state.reservations
      .filter((r) => r.isWaitingForPickup())
      .sort(sortByDate);
  },
};

const actions = {
  async loadReservations({ commit, state }, forceRefresh) {
    if (state.reservations.length === 0 || forceRefresh) {
      commit(types.RESERVATIONS_SET_LOADING, true);
      const reservations = await reservationsApi.loadReservations();

      commit(types.RESERVATIONS_LOAD_RESERVATIONS, reservations);
      commit(types.RESERVATIONS_SET_LOADING, false);
    }
  },
  async refreshReservation({ commit }, id) {
    const response = await reservationsApi.loadReservation(id);
    commit(types.RESERVATIONS_UPDATE_RESERVATION, response);
    return response;
  },
  async markReady({ commit }, reservation) {
    const response = await reservationsApi.markReady(reservation);
    commit(types.RESERVATIONS_UPDATE_RESERVATION, response);
  },
  async manualCheckin({ commit }, reservation) {
    const response = await reservationsApi.manualCheckin(reservation);
    commit(types.RESERVATIONS_UPDATE_RESERVATION, response);
  },
  async confirmCheckin({ commit }, reservation) {
    const response = await reservationsApi.confirmCheckin(reservation);
    commit(types.RESERVATIONS_UPDATE_RESERVATION, response);
  },
  async completePickup({ commit }, reservation) {
    const response = await reservationsApi.completePickup(reservation);
    commit(types.RESERVATIONS_UPDATE_RESERVATION, response);
  },
};

const mutations = {
  [types.RESERVATIONS_LOAD_RESERVATIONS](state, reservations) {
    state.reservations = reservations;
  },
  [types.RESERVATIONS_UPDATE_RESERVATION](state, reservation) {
    let updated = false;
    state.reservations = state.reservations.map((item) => {
      if (reservation.id === item.id) {
        updated = true;
        return reservation;
      }
      return item;
    });

    if (!updated) state.reservations.push(reservation);
  },
  [types.RESERVATIONS_SET_LOADING](state, loading) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
