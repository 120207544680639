<template>
  <v-app>
    <app-bar></app-bar>
    <app-navigation-drawer></app-navigation-drawer>
    <v-main
      class="main-container"
      :class="{ 'white-background': !$route.meta.fullScreen }"
    >
      <router-view></router-view>
    </v-main>
    <pickup-alert-overlay></pickup-alert-overlay>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppBar from '@/components/AppBar';
import PickupAlertOverlay from '@/components/PickupAlertOverlay';
import AppNavigationDrawer from '@/components/AppNavigationDrawer';
import PusherService from '@/plugins/pusher';
import eventBus from '@/event-bus';

export default {
  name: 'App',
  components: { AppNavigationDrawer, AppBar, PickupAlertOverlay },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
      'token',
      'booth',
    ]),
  },
  watch: {
    isAuthenticated(value) {
      this.onAuthStateChanged(value);
    },
  },
  created() {
    this.init();
    this.onAuthStateChanged(this.isAuthenticated);
  },
  methods: {
    ...mapActions([
      'init',
    ]),
    ...mapActions('reservations', [
      'refreshReservation',
    ]),
    onAuthStateChanged(isAuthenticated) {
      if (isAuthenticated) {
        if (!process.env.VUE_APP_MOCK_API) {
          const { channel } = PusherService(this.token, this.booth.id);

          channel.bind('reservation-updated', async (data) => {
            const r = await this.refreshReservation(data.id);
            if (r.isWaitingForPickup() && r.checkin_type === 'qr_code' && !r.checkin_confirmed_by_employee) {
              eventBus.$emit('showPickupAlert', r);
            }
          });

          channel.bind('new-reservation', async (data) => {
            const r = await this.refreshReservation(data.id);
            if (r.isToday) eventBus.$emit('showNewReservationAlert', r);
          });
        }
      } else if (this.$route.name !== 'login') this.$router.replace('login');
    },
  },
};
</script>

<style lang="scss">
@import "styles/base";
</style>
