<template>
  <v-btn
    rounded x-large dark
    color="green"
    class="text-button"
    v-text="$t('completePickup')"
    :loading="loading"
    :disabled="loading"
    @click="completePickupButton"
  ></v-btn>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'CompletePickupButton',
  props: {
    reservation: Object,
    disabled: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions('reservations', [
      'completePickup',
    ]),
    async completePickupButton() {
      this.loading = true;
      await this.completePickup(this.reservation);
      this.loading = false;
      await this.$router.push('/');
    },
  },
};
</script>
