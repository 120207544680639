import VuexPersistence from 'vuex-persist';
import Cookies from 'js-cookie';

const persistAuth = new VuexPersistence({
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) => Cookies.set(key, state, {
    expires: new Date('2038-01-19T03:14:07.000Z'), // Expires basically never
  }),
  modules: ['auth'],
});

export default persistAuth.plugin;
