<template>
  <v-chip
    v-if="isDev"
    class="ml-4"
    label small
    v-text="envName"
    :color="color"
  ></v-chip>
</template>

<script>
const envName = process.env.VUE_APP_ENV_NAME;
const mockApi = process.env.VUE_APP_MOCK_API;

export default {
  name: 'EnvLabel',
  computed: {
    isDev() {
      return envName !== 'Production';
    },
    envName() {
      let name = envName;
      if (mockApi) {
        name += ' (Mocked API)';
      }
      return name;
    },
    color() {
      switch (envName) {
      case 'Staging':
        return 'red';
      default:
        return 'green';
      }
    },
  },
};
</script>
