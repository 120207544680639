<template>
  <v-col class="reservations-list-column">
    <h3 class="text-h6 mb-2" v-text="title"></h3>
    <div class="reservations-list-column__scroll">
      <div
        v-for="group in groupedItems"
        :key="`${_uid}-${group.label}`"
      >
        <h6 v-if="!loading" class="text-overline" v-text="group.label"></h6>

        <template v-if="!loading">
          <reservation-card
            v-for="reservation in group.reservations"
            :key="reservation.id"
            :reservation="reservation"
          ></reservation-card>
        </template>
        <v-skeleton-loader
          v-if="loading"
          type="list-item-two-line"
          class="mt-9"
        ></v-skeleton-loader>
        <reservation-card-placeholder
          v-else-if="group.reservations.length === 0"
        ></reservation-card-placeholder>
      </div>

      <reservation-card-placeholder
        v-if="groupedItems.length === 0 &&!loading"
        class="mt-8"
      >{{ $t('noReservations')}}</reservation-card-placeholder>
      <v-skeleton-loader
        v-else-if="loading"
        type="list-item-two-line"
      ></v-skeleton-loader>
    </div>
  </v-col>
</template>

<script>
import ReservationCard from '@/views/reservation-overview/components/ReservationCard';
import ReservationCardPlaceholder from '@/views/reservation-overview/components/ReservationCardPlaceholder';
import { groupReservationsByDays } from '@/utils/reservations';

export default {
  name: 'ReservationsListColumn',
  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
    loading: Boolean,
  },
  components: { ReservationCardPlaceholder, ReservationCard },
  computed: {
    groupedItems() {
      return groupReservationsByDays(this.items);
    },
  },
};
</script>

<style lang="scss">
.reservations-list-column {
  &__scroll {
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
