import Vue from 'vue';
import App from './App';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './plugins/sentry';

Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.config.productionTip = false;

async function runApp() {
  if (process.env.VUE_APP_MOCK_API) {
    const runMirageServer = (await import('@/api/mocks'/* webpackChunkName: "dev" */)).default;
    runMirageServer();
  }

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
}

runApp();
