import HTTP from '@/plugins/http';
import ReservationProfile from '@/models/reservation-profile';

export default {
  async loadReservationProfile(id) {
    const response = await HTTP.get(`/api/employee/reservation-profiles/${id}/`);
    return new ReservationProfile(response.data);
  },
  async markReady(profile) {
    const response = await HTTP.patch(`/api/employee/reservation-profiles/${profile.id}/mark_ready/`);
    return new ReservationProfile(response.data);
  },
  async addCard(profile, ticketcornerNumber) {
    const response = await HTTP.patch(`/api/employee/reservation-profiles/${profile.id}/add_card/`, {
      ticketcorner_number: ticketcornerNumber,
    });
    return new ReservationProfile(response.data);
  },
};
