<template>
  <v-btn
    rounded x-large
    color="primary"
    class="text-button"
    v-text="$t('markAsReady')"
    :loading="loading"
    :disabled="disabled || loading"
    @click="markReadyButton"
  ></v-btn>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'MarkReadyButton',
  props: {
    reservation: Object,
    disabled: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions('reservations', [
      'markReady',
    ]),
    async markReadyButton() {
      this.loading = true;
      await this.markReady(this.reservation);
      this.loading = false;

      if (!this.reservation.isWaitingForPickup()) await this.$router.push('/');
    },
  },
};
</script>
