/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import * as types from '../mutation-types';

const state = {
  appDrawerOpen: false,
};

const getters = {
  appDrawerOpen: (state) => state.appDrawerOpen,
};

const actions = {
  async toggleAppDrawer({ commit }) {
    commit(types.NAVIGATION_TOGGLE_APP_DRAWER);
  },
};

const mutations = {
  [types.NAVIGATION_TOGGLE_APP_DRAWER](state) {
    state.appDrawerOpen = !state.appDrawerOpen;
  },
  [types.NAVIGATION_SET_APP_DRAWER](state, appDrawerOpen) {
    state.appDrawerOpen = appDrawerOpen;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
