import Vue from 'vue';
import VueRouter from 'vue-router';
import ReservationDetail from '@/views/reservation-detail/ReservationDetail';
import ReservationOverview from '@/views/reservation-overview/ReservationOverview';
import Login from '@/views/Login';
// import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      fullScreen: true,
      hideControls: true,
      compactHeader: true,
    },
  },
  {
    path: '/',
    name: 'home',
    component: ReservationOverview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reservation/:id',
    name: 'reservation-detail',
    component: ReservationDetail,
    props: true,
    meta: {
      requiresAuth: true,
      compactHeader: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(() => {
  window.location.href = 'https://hyll.com';
});

/* router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (to.name === 'login' && isAuthenticated) {
    next('/');
  } else {
    next();
  }
}); */

export default router;
