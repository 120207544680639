import HTTP from '@/plugins/http';
import Reservation from '@/models/reservation';

export default {
  async loadReservations() {
    const response = await HTTP.get('/api/employee/reservations/');
    if (process.env.VUE_APP_MOCK_API) {
      return response.data.reservations.map((r) => new Reservation(r));
    }
    return response.data.data.map((r) => new Reservation(r));
  },
  async loadReservation(id) {
    const response = await HTTP.get(`/api/employee/reservations/${id}/`);
    return new Reservation(response.data);
  },
  async markReady(reservation) {
    const response = await HTTP.patch(`/api/employee/reservations/${reservation.id}/mark_ready/`);
    return new Reservation(response.data);
  },
  async manualCheckin(reservation) {
    const response = await HTTP.patch(`/api/employee/reservations/${reservation.id}/manual_checkin/`);
    return new Reservation(response.data);
  },
  async confirmCheckin(reservation) {
    const response = await HTTP.patch(`/api/employee/reservations/${reservation.id}/confirm_checkin/`);
    return new Reservation(response.data);
  },
  async completePickup(reservation) {
    const response = await HTTP.patch(`/api/employee/reservations/${reservation.id}/complete_pickup/`);
    return new Reservation(response.data);
  },
};
