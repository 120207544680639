<template>
  <v-container class="pt-2 fill-height align-content-start">
    <v-row v-if="waitingReservations.length > 0">
      <v-col>
        <h3 class="text-h6 mb-2" v-text="$t('waitingReservations')"></h3>
        <reservation-card
          v-for="reservation in waitingReservations"
          :key="reservation.id"
          :reservation="reservation"
          waiting-for-pickup
        ></reservation-card>
      </v-col>
    </v-row>
    <v-row>
      <ReservationsListColumn
        :title="$t('openReservations')"
        :items="openReservations"
        :loading="loading"
      />
      <ReservationsListColumn
        :title="$t('readyReservations')"
        :items="readyReservations"
        :loading="loading"
      />
    </v-row>
    <bottom-action-bar v-if="mockApi">
      <v-btn
        class="text-button mr-2"
        color="primary"
        rounded
        @click="showAlert"
      >Show Alert</v-btn>
      <v-btn
        class="text-button mr-2"
        color="primary"
        rounded
        @click="showNewAlert"
      >Show New Alert</v-btn>
    </bottom-action-bar>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BottomActionBar from '@/components/BottomActionBar';
import ReservationsListColumn from '@/views/reservation-overview/components/ReservationsListColumn';
import ReservationCard from '@/views/reservation-overview/components/ReservationCard';
import HTTP from '@/plugins/http';
import eventBus from '@/event-bus';

export default {
  name: 'ReservationOverview',
  components: { ReservationsListColumn, BottomActionBar, ReservationCard },
  data() {
    return {
      updateInterval: null,
    };
  },
  computed: {
    ...mapGetters('reservations', [
      'reservations',
      'openReservations',
      'readyReservations',
      'waitingReservations',
      'loading',
    ]),
    mockApi() {
      return process.env.VUE_APP_MOCK_API;
    },
  },
  created() {
    this.updateInterval = setInterval(
      () => this.loadReservations(true),
      300 * 1000,
    );
    this.loadReservations(true);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
  methods: {
    ...mapActions('reservations', [
      'loadReservations',
    ]),
    async showAlert() {
      if (this.readyReservations.length > 0) {
        const res = this.readyReservations[0];
        await HTTP.patch(`/api/employee/reservations/${res.id}/qr_checkin/`);
        await eventBus.$emit('showPickupAlert', res);
        await this.loadReservations(true);
      }
    },
    async showNewAlert() {
      if (this.openReservations.length > 0) {
        const res = this.openReservations[0];
        eventBus.$emit('showNewReservationAlert', res);
      }
    },
  },
};
</script>
