<template>
  <v-expansion-panels
    :key="expansionPanelsKey"
    v-model="step"
  >
    <v-progress-linear
      v-if="loading"
      indeterminate
    ></v-progress-linear>

    <profile-card
      v-for="(profile, index) in profiles"
      :key="profile.id"
      :profile="profile"
      :edit="edit"
      @change="onProfileChange(index, $event)"
    ></profile-card>
  </v-expansion-panels>
</template>

<script>
import ProfileCard from '@/views/reservation-detail/components/ProfileCard';
import ReservationProfilesApi from '@/api/reservation-profiles';

export default {
  name: 'ReservationProfiles',
  components: {
    ProfileCard,
  },
  props: {
    reservation: Object,
    edit: Boolean,
  },
  data() {
    return {
      profiles: [],
      loading: false,
      expansionPanelsKey: 0,
      step: 0,
    };
  },
  async created() {
    this.loading = true;
    // Load profiles
    this.profiles = await Promise.all(this.reservation.profiles.map(
      (p) => ReservationProfilesApi.loadReservationProfile(p.id),
    ));

    this.checkProfiles();
    this.loading = false;
  },
  methods: {
    onProfileChange(index, profile) {
      this.profiles[index] = profile;
      if (profile.preparation_status === 'ready_for_pickup') {
        this.step += 1;
      } else {
        this.expansionPanelsKey += 1; // Force rebuild of expansion panels
      }
      this.checkProfiles();
    },
    checkProfiles() {
      const allProfilesCompleted = this.profiles.every((p) => p.preparation_status === 'ready_for_pickup');
      this.$emit('valid', allProfilesCompleted);
    },
  },
};
</script>

<style scoped>

</style>
