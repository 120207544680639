<template>
  <v-container class="fill-height">
    <v-row class="login-form justify-center">
      <v-col cols="8" class="theme--dark fill-height">
        <h3 class="text-h6">{{ $t('login.getStarted') }}</h3>
        <v-form ref="loginForm" class="mt-5">
          <v-text-field
            filled dark
            :label="$t('login.username')"
            v-model="loginForm.boothId.field"
            :rules="loginForm.boothId.rules"
            @keydown.enter="loginButton"
          ></v-text-field>
          <v-text-field
            filled dark
            type="password"
            :label="$t('login.password')"
            v-model="loginForm.passcode.field"
            :rules="loginForm.passcode.rules"
            @keydown.enter="loginButton"
          ></v-text-field>

          <v-alert
            v-if="error"
            color="error"
          >{{ error }}</v-alert>

          <v-btn
            block x-large rounded
            color="primary"
            class="text-button"
            v-text="$t('login.submit')"
            :loading="loading"
            :disabled="loading"
            @click="loginButton"
          ></v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <language-select compact></language-select>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import LanguageSelect from '@/components/LanguageSelect';

export default {
  name: 'Login',
  components: { LanguageSelect },
  data() {
    return {
      loading: false,
      error: null,
      loginForm: {
        boothId: {
          field: '',
          rules: [
            (v) => !!v || this.$t('validation.fieldRequired'), // Check if field is empty
          ],
        },
        passcode: {
          field: '',
          rules: [
            (v) => !!v || this.$t('validation.fieldRequired'), // Check if field is empty
          ],
        },
      },
    };
  },
  methods: {
    ...mapActions('auth', [
      'login',
    ]),
    async loginButton() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        this.error = null;

        try {
          await this.login({
            username: this.loginForm.boothId.field.trim(),
            password: this.loginForm.passcode.field.trim(),
          });

          await this.$router.push('/');
        } catch (e) {
          if (e.response && e.response.status === 400) {
            this.error = e.response.data.message;
          } else {
            this.error = e.message;
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.login-form {
  margin-top: 20%;
}
</style>
