/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import * as Sentry from '@sentry/browser';
import AuthApi from '@/api/auth';
import HTTP from '@/plugins/http';
import * as types from '../mutation-types';

const state = {
  token: null,
  booth: null,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  token: (state) => state.token,
  booth: (state) => state.booth,
};

const actions = {
  async init({ state, dispatch }) {
    dispatch('setToken', state.token);
    dispatch('setBooth', state.booth);

    // Logout on invalid token
    HTTP.interceptors.response.use((r) => r, (error) => {
      if (error.response && error.response.status === 403) {
        const errorCode = error.response.data.code;
        console.error('Authentication Error', errorCode);

        if (errorCode === 'AuthenticationFailed') {
          dispatch('logout');
        }
      }
      return Promise.reject(error);
    });
  },
  async login({ dispatch }, payload) {
    const response = await AuthApi.login(payload.username, payload.password);
    dispatch('setBooth', response.data);
    dispatch('setToken', response.data.token);
  },
  async logout({ dispatch }) {
    try {
      await AuthApi.logout();
      // eslint-disable-next-line no-empty
    } catch (e) {}

    dispatch('setBooth', null);
    dispatch('setToken', null);
  },
  async setToken({ commit }, token) {
    // Set axios auth headers
    HTTP.defaults.headers.common.Authorization = token ? `Token ${token}` : null;
    commit(types.AUTH_SET_TOKEN, token);
  },
  async setBooth({ commit }, booth) {
    if (booth && process.env.VUE_APP_LOG_SENTRY) {
      Sentry.setUser({
        id: booth.id,
        username: booth.title,
      });
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
    commit(types.AUTH_SET_BOOTH, booth);
  },
};

const mutations = {
  [types.AUTH_SET_BOOTH](state, booth) {
    state.booth = booth;
  },
  [types.AUTH_SET_TOKEN](state, token) {
    state.token = token;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
