<template>
  <v-row no-gutters>
    <profile-attribute-chip
        v-if="profileSnapshot.gender"
        :icon="$t(`person.gender.icon.${profileSnapshot.gender}`)"
        :tooltip="$t(`person.gender.name`)"
    >
      {{ $t(`person.gender.${profileSnapshot.gender}`) }}
    </profile-attribute-chip>

    <profile-attribute-chip
      v-if="profileSnapshot.date_of_birth"
      icon="mdi-calendar-account"
      :tooltip="$t(`person.date_of_birth.name`)"
    >
      {{ profileSnapshot.date_of_birth|formatDate }}
    </profile-attribute-chip>

    <profile-attribute-chip
      v-if="profileSnapshot.age"
      icon="mdi-calendar-account"
      :tooltip="$t(`person.age.name`)"
    >
      {{ profileSnapshot.age }}
    </profile-attribute-chip>

    <profile-attribute-chip
      v-if="profileSnapshot.height"
      icon="mdi-human-male-height-variant"
      :tooltip="$t(`person.height.name`)"
    >
      {{ profileSnapshot.height }} cm
    </profile-attribute-chip>

    <profile-attribute-chip
        v-if="profileSnapshot.weight"
        icon="mdi-weight"
        :tooltip="$t(`person.weight.name`)"
    >
      {{ profileSnapshot.weight }} kg
    </profile-attribute-chip>

    <profile-attribute-chip
        v-if="profileSnapshot.level"
        icon="mdi-ski"
        :tooltip="$t(`person.level.name`)"
    >
      {{ $t(`person.level.${profileSnapshot.level}`) }}
    </profile-attribute-chip>

    <profile-attribute-chip
        v-if="profileSnapshot.shoe_size"
        icon="mdi-foot-print"
        :tooltip="$t(`person.shoe_size.name`)"
    >
      {{ profileSnapshot.shoe_size }}
    </profile-attribute-chip>

    <profile-attribute-chip
        v-if="profileSnapshot.shoe_sole_length"
        icon="mdi-shoe-cleat"
        :tooltip="$t(`person.shoe_sole_length.name`)"
    >
      SL {{ profileSnapshot.shoe_sole_length }} mm
    </profile-attribute-chip>

    <profile-attribute-chip
        v-if="profileSnapshot.foot_position"
        icon="mdi-snowboard"
        :tooltip="$t(`person.foot_position.name`)"
    >
      {{ $t(`person.foot_position.${profileSnapshot.foot_position}`) }}
    </profile-attribute-chip>

    <profile-attribute-chip
        v-if="profileSnapshot.helmet_size"
        icon="mdi-racing-helmet"
        :tooltip="$t(`person.helmet_size.name`)"
    >
      {{ profileSnapshot.helmet_size }} cm
    </profile-attribute-chip>
  </v-row>
</template>
<script>
import moment from 'moment';
import ProfileAttributeChip from '@/views/reservation-detail/components/ProfileAttributeChip';

export default {
  name: 'ProfileAttributes',
  components: { ProfileAttributeChip },
  props: {
    profileSnapshot: {},
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
  },
};
</script>
