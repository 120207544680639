<template>
  <div>
    <v-btn
      rounded small
      class="mr-2"
      color="primary"
      v-if="!hasCard && isBarcodeScannerSupported"
      v-text="$t('addSkipass')"
      @click="openScanner"
    ></v-btn>

    <numeric-input-dialog
      v-if="!hasCard"
      :label="$t('enterSkipassId')"
      :max-length="30"
      @input="onCode($event)"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          rounded small
          class="mr-2"
          v-text="$t('enterSkipassId')"
          v-on="on"
        ></v-btn>
      </template>
    </numeric-input-dialog>

    <v-chip v-if="hasCard">
      <span v-text="card.card_type_display_name + ' ' + card.card_number"></span>
    </v-chip>
  </div>
</template>

<script>
/* global Android */

import NumericInputDialog from '@/components/NumericInputDialog';

export default {
  name: 'SkiPassTableCell',
  components: { NumericInputDialog },
  props: {
    card: {
      type: Object,
    },
  },
  data() {
    return {
      isBarcodeScannerSupported: false,
    };
  },
  computed: {
    hasCard() {
      return !!this.card && !!this.card.card_number;
    },
  },
  created() {
    // Kiosk browser will not let you define a specific callback, so we have to redirect
    // window.barcodeScanResult to our callback.
    window.barcodeScanResult = this.onCode;
    this.isBarcodeScannerSupported = typeof Android !== 'undefined';
  },
  destroyed() {
    // Properly unlink the callback
    window.barcodeScanResult = null;
  },
  methods: {
    openScanner() {
      if (this.isBarcodeScannerSupported) {
        Android.openBarcodeScannerResultCallback();
      }
    },
    onCode(code) {
      const codeElements = code.split('/');
      const cardNumber = codeElements[codeElements.length - 1];
      this.$emit('input', cardNumber);
    },
  },
};
</script>
