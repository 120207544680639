<template>
  <v-row class="justify-end align-center" no-gutters>
    <span v-text="dateString"></span>
    <v-spacer></v-spacer>
    <template v-if="isKioskBrowser">
      <!-- Battery Level -->
      <v-icon small v-text="isCharging ? 'mdi-battery-charging' : 'mdi-battery'"></v-icon>
      <span>{{ batteryLevel }}%</span>
    </template>
  </v-row>
</template>

<script>
/* global Android */

import moment from 'moment';

export default {
  name: 'DeviceStatusBar',
  data() {
    return {
      isKioskBrowser: false,
      updateInterval: -1,
      dateString: '',
      isCharging: false,
      batteryLevel: 0,
    };
  },
  created() {
    this.isKioskBrowser = typeof Android !== 'undefined';

    this.updateInterval = setInterval(this.updateInfo, 5 * 1000);
    this.updateInfo();
  },
  methods: {
    updateInfo() {
      this.dateString = moment().format('dd, D.M HH:mm');
      if (this.isKioskBrowser) {
        this.isCharging = Android.isCharging();
        this.batteryLevel = Math.round(Android.getBatteryLevel());
      }
    },
  },
};
</script>
