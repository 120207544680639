import Pusher from 'pusher-js';

if (process.env.NODE_ENV !== 'production') Pusher.logToConsole = true;

export default (token, boothId) => {
  const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_ID, {
    cluster: 'eu',
    authEndpoint: `${process.env.VUE_APP_API_BASE_URL}/api/employee/pusher_auth/`,
    auth: {
      headers: {
        Authorization: `Token ${token}`,
      },
    },
  });

  const channel = pusher.subscribe(`private-booth-${boothId}`);

  return {
    pusher,
    channel,
  };
};
