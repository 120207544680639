import HTTP from '@/plugins/http';

export default {
  async login(username, password) {
    return HTTP.post('/api/employee/login/', {
      username,
      password,
    });
  },
  async logout() {
    return HTTP.post('/api/employee/logout/');
  },
};
