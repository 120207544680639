import Vue from 'vue';
import Vuex from 'vuex';

import PersistAuthPlugin from './plugins/persist-auth';
import PersistSettingsPlugin from './plugins/persist-settings';

import auth from './modules/auth';
import localSettings from './modules/local-settings';
import navigation from './modules/navigation';
import reservations from './modules/reservations';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    localSettings,
    navigation,
    reservations,
  },
  actions: {
    async init({ dispatch }) {
      await dispatch('auth/init');
      await dispatch('localSettings/init');
    },
  },
  plugins: [
    PersistAuthPlugin,
    PersistSettingsPlugin,
  ],
});
